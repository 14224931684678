import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { Button } from '../1TalkDesign/Buttons';
import theme from '../../theme';
import { toMonth, toDay, toDayOfWeekNumberOnly } from '../../utils/date';
import { getURLSearchParamByKey } from '../../utils';
import { t } from 'src/i18n/config';

const Container = styled.div`
  margin-top: 16px;
  border: ${(props) => props.theme.boxBorder.active};
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
  opacity: ${(props) => (props.deleted ? '0.4' : 'unset')};
  pointer-events: ${(props) => (props.deleted ? 'none' : 'unset')};
  ${({ isHideSeq }) =>
    isHideSeq &&
    `
      ${DateString},
      ${CurrentNumber},
      ${CurrentNumberNotFound},
      ${PatientNumberTitle},
      ${PatientNumberValue},
      ${Waiting} {
        display: none;
      }
      ${LeftSection} {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        height: 100%;
      }
  `}
`;

const Patient = styled.div`
  padding: 8px 0;
  border-bottom: ${(props) => props.theme.boxBorder.active};
`;
const DateString = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.textSecondary};
`;
const HideSeqDateString = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin-top: 8px;
  span {
    font-size: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.07px;
  }
`;
const MainSection = styled.div`
  margin-top: 6px;
  margin-bottom: 8px;
  height: 106px;
  font-size: 1rem;
  width: 100%;
  display: flex;
`;
const LeftSection = styled.div`
  width: 50%;
  border-right: ${(props) => props.theme.boxBorder.default};
`;
const RightSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const DoctorName = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.07px;
`;
const Subject = styled.div`
  margin-top: 8px;
`;
const CurrentNumber = styled.div`
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.33;
  color: ${(props) => props.theme.colors.primary};
`;
const CurrentNumberNotFound = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.textSecondary};
`;
const PatientNumberTitle = styled.div`
  color: ${(props) => props.theme.colors.textSecondary};
  line-height: 1.54;
  letter-spacing: 0.09px;
`;
const PatientNumberValue = styled.div`
  margin-top: 8px;
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.primary};
`;
const Waiting = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.textSecondary};
`;
const WaitingNumber = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;
const CancelSection = styled.div`
  border-top: ${(props) => props.theme.boxBorder.active};
`;
const CancelButton = styled(Button)`
  width: 100%;
  height: 47px;
  font-weight: 500;
  line-height: 1.73;
  letter-spacing: 0.3px;
  text-align: center;
`;

function AppointmentCard({ appointment }) {
  const {
    name,
    relation,
    date,
    time,
    doctorName,
    subject,
    room,
    currentNumber,
    number,
    waiting,
    cancelable,
    id,
    deleted,
    appointmentTime,
  } = appointment;
  const hideSeq = !!getURLSearchParamByKey('hideSeq');
  const hideWaitingInfo = !!getURLSearchParamByKey('hideWaitingInfo');
  const handleClickCancel = () => {
    navigate('/appointments/cancel', { state: { appointment: appointment } });
  };

  return (
    <Container deleted={deleted} isHideSeq={hideSeq}>
      <Patient>
        {name} {`(${relation})`}
      </Patient>
      <DateString>
        {`${toMonth(date)} ${t('month')} ${toDay(date)} ${t(
          'day'
        )} (${toDayOfWeekNumberOnly(date)})`}{' '}
        {`${time} ${appointmentTime}`}
      </DateString>
      <MainSection>
        <LeftSection>
          <DoctorName>{t('doctor', { name: doctorName })}</DoctorName>
          <Subject>
            {subject} {room}
          </Subject>
          {currentNumber && `${currentNumber}` !== 'unknow' ? (
            <CurrentNumber>
              {currentNumber} {t('number')}
            </CurrentNumber>
          ) : (
            <CurrentNumberNotFound>{t('noProgress')}</CurrentNumberNotFound>
          )}
        </LeftSection>
        <RightSection>
          <PatientNumberTitle>{t('yourNumber')}</PatientNumberTitle>
          <PatientNumberValue>
            {number} {t('number')}
          </PatientNumberValue>
          {!hideWaitingInfo && waiting !== 0 && (
            <Waiting>
              {t('waitingCount')}: <WaitingNumber>{waiting}</WaitingNumber>{' '}
              {t('people')}
            </Waiting>
          )}
          {hideSeq && (
            <HideSeqDateString>
              {`${toMonth(date)} ${t('month')} ${toDay(date)} ${t(
                'day'
              )} (${toDayOfWeekNumberOnly(date)})`}
              <span>{`${time} ${appointmentTime}`}</span>
            </HideSeqDateString>
          )}
        </RightSection>
      </MainSection>
      {cancelable && (
        <CancelSection>
          <CancelButton
            customizedcolor={'transparent'}
            customizedfontcolor={theme.colors.error}
            onClick={handleClickCancel}
          >
            {deleted ? t('cancelled') : t('cancel')}
          </CancelButton>
        </CancelSection>
      )}
    </Container>
  );
}

AppointmentCard.defaultProps = {
  appointment: {},
};

AppointmentCard.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    relation: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    time: PropTypes.string,
    doctorName: PropTypes.string,
    subject: PropTypes.string,
    room: PropTypes.string,
    currentNumber: PropTypes.number,
    number: PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
    waiting: PropTypes.number,
    cancelable: PropTypes.bool,
    appointmentTime: PropTypes.string,
  }),
};

AppointmentCard.defaultProps = {
  availables: [],
};
export default AppointmentCard;
